exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bedrijven-jsx": () => import("./../../../src/pages/bedrijven.jsx" /* webpackChunkName: "component---src-pages-bedrijven-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nieuws-jsx": () => import("./../../../src/pages/nieuws.jsx" /* webpackChunkName: "component---src-pages-nieuws-jsx" */),
  "component---src-pages-over-ons-jsx": () => import("./../../../src/pages/over-ons.jsx" /* webpackChunkName: "component---src-pages-over-ons-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-producten-categorie-index-jsx": () => import("./../../../src/pages/producten/categorie/index.jsx" /* webpackChunkName: "component---src-pages-producten-categorie-index-jsx" */),
  "component---src-pages-producten-categorie-shopify-collection-handle-jsx": () => import("./../../../src/pages/producten/categorie/{ShopifyCollection.handle}.jsx" /* webpackChunkName: "component---src-pages-producten-categorie-shopify-collection-handle-jsx" */),
  "component---src-pages-producten-index-jsx": () => import("./../../../src/pages/producten/index.jsx" /* webpackChunkName: "component---src-pages-producten-index-jsx" */),
  "component---src-pages-producten-op-maat-jsx": () => import("./../../../src/pages/producten-op-maat.jsx" /* webpackChunkName: "component---src-pages-producten-op-maat-jsx" */),
  "component---src-pages-producten-shopify-product-handle-jsx": () => import("./../../../src/pages/producten/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-producten-shopify-product-handle-jsx" */),
  "component---src-pages-verkooppunten-jsx": () => import("./../../../src/pages/verkooppunten.jsx" /* webpackChunkName: "component---src-pages-verkooppunten-jsx" */),
  "component---src-pages-winkelwagen-jsx": () => import("./../../../src/pages/winkelwagen.jsx" /* webpackChunkName: "component---src-pages-winkelwagen-jsx" */)
}

